import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import SplashScreen from './views/SplashScreen';
import LoginScreen from './views/LoginScreen';
import RegisterScreen from './views/RegisterScreen';
import ProductCatalog from './views/ProductCatalog';
import ProductCatalogOverlay from './views/ProductCatalogOverlay';
import { useAppId } from './AppIdContext';
import configLoader from './utils/configLoader';
import initializeI18n from './i18n';
import ItemDetails from "./views/ItemDetails";
import Cart from "./views/Cart";
import Orders from "./views/Orders";
import OrderDetails from "./views/OrderDetails";
import Address from "./views/Address";
import AddressForm from "./views/AddressForm";
import Checkout from "./views/Checkout";
import CheckoutConfirmation from "./views/CheckoutConfirmation";
import KashierPayment from "./views/KashierPayment";

import Skeleton from "react-loading-skeleton";

const App = () => {
    const [config, setConfig] = useState(null);
    const [categories, setCategories] = useState([]);
    const [offers, setOffers] = useState([]);
    const [branches, setBranches] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const appId = useAppId();
    const navigate = useNavigate();

    import(`./projects/${appId}/app_style.css`);

    const logoPath = `/projects/${appId}/images/product_catalog_block_logo.png`;

    const backgroundPath = `/projects/${appId}/images/cover.png`;

    const style = {
        background: `url(${backgroundPath}) no-repeat center center `,
        backgroundSize: 'cover',
    };

    useEffect(() => {
        const loadConfigAndInitializeI18n = async () => {
            try {
                await initializeI18n(appId);
                const loadedConfig = await configLoader(appId);
                setConfig(loadedConfig);
            } catch (error) {
                console.error('Failed to load config or initialize i18n:', error);
            }
        };

        loadConfigAndInitializeI18n();
    }, [appId]);


    useEffect(() => {
        if (config && config.global_facebook_pixel_id) {
            const pixelId = config.global_facebook_pixel_id;

            // Avoid duplicate scripts
            if (!document.getElementById("fb-pixel-script")) {
                const script = document.createElement("script");
                script.id = "fb-pixel-script";
                script.innerHTML = `
                    !function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window, document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '${pixelId}');
                    fbq('track', 'PageView');
                `;
                document.head.appendChild(script);

                // Add a noscript tag for fallback tracking
                const noscript = document.createElement("noscript");
                noscript.innerHTML = `
                    <img height="1" width="1" 
                    src="https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1"/>
                `;
                document.head.appendChild(noscript);
            }
        }
    }, [config]);


    const renderLoadingSkeletons = () => (
        <div className="splash_layout">
            <Skeleton height={800} />
        </div>
    );

    if (!config) {
        return renderLoadingSkeletons(); // Show a loading state while configuration is being loaded
    }

    const handleSplashComplete = (nextScreen, loadedCategories, loadedOffers, loadedBranches) => {
        setCategories(loadedCategories);
        setOffers(loadedOffers);
        setBranches(loadedBranches);
        navigate(nextScreen || '/catalog');
    };

    const handleItemSelected = (item) => {
        setSelectedItem(item);
    };

    return (
        <div class="app_holder">
            <div className="App">
                <Routes>
                    <Route path="/splash" element={<SplashScreen onComplete={handleSplashComplete} config={config} />} />
                    <Route path="/login" element={<LoginScreen config={config} />} />
                    <Route path="/register" element={<RegisterScreen config={config} />} />
                    <Route path="/catalog" element={<ProductCatalog config={config} onItemSelect={handleItemSelected} />} />
                    <Route path="/overlay" element={<ProductCatalogOverlay config={config} />} />
                    <Route path="/itemDetails" element={<ItemDetails item={selectedItem} config={config} />} />
                    <Route path="/cart" element={<Cart config={config} />} />
                    <Route path="/checkout" element={<Checkout config={config} />} />
                    <Route path="/checkout/confirmation" element={<CheckoutConfirmation config={config} />} />
                    <Route path="/address" element={<Address />} />
                    <Route path="/address/form" element={<AddressForm config={config} />} />
                    <Route path="/orders" element={<Orders />} />
                    <Route path="/order/details" element={<OrderDetails />} />
                    <Route path="/kashierPayment" element={<KashierPayment />} />
                    <Route path="/" element={<SplashScreen onBack={() => navigate('/catalog')} onComplete={handleSplashComplete} config={config} />} />
                </Routes>
            </div>
            <div className="cover" style={style}>
                <div class="cover-logo"><img src={logoPath} /></div>
            </div>
        </div>

    );
};

export default App;
