import React, { useState } from 'react';
import cart from '../services/cart';
import { useAppId } from "../AppIdContext";

const CartBlockItemsItem = ({ item, onQuantityChange }) => {
    const appId = useAppId();
    const [quantity, setQuantity] = useState(item.quantity);

    const handleQuantityChange = (newQuantity) => {
        if (newQuantity <= 0) {
            cart.removeItem(item.id, item.selectedVariation, item.selectedAddOns);
        } else {
            cart.updateQuantity(item.id, newQuantity, item.selectedVariation, item.selectedAddOns);
            setQuantity(newQuantity);
        }
        onQuantityChange();
    };

    const plus = `/projects/${appId}/images/plus_up.png`;
    const minus = `/projects/${appId}/images/minus_icon.png`;

    const formatVariationOrAddon = (name, price) => (
        <div className="cart_block_items_item_variant">
            <div className="cart_block_items_item_variant_name">{name}</div>
            <div className="cart_block_items_item_variant_price">{price}</div>
        </div>
    );

    const formatDate = (dateStr, withTime = true) => {
        const date = new Date(dateStr);
        const options = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        };
        if (withTime) {
            options.hour = '2-digit';
            options.minute = '2-digit';
        }
        return new Intl.DateTimeFormat('en-GB', options).format(date);
    };

    const formatSlots = (start, end, price) => {
        const startDate = new Date(start);
        const endDate = end ? new Date(end) : null;

        let formattedTime;
        if (endDate && startDate.toDateString() === endDate.toDateString()) {
            formattedTime = `${formatDate(start, false)} ${startDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} to ${endDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
        } else {
            formattedTime = `${formatDate(start)} ${end ? `to ${formatDate(end)}` : ''}`;
        }

        return (
            <div className="cart_block_items_item_variant">
                <div className="cart_block_items_item_variant_name">{formattedTime}</div>
                <div className="cart_block_items_item_variant_price">{price}</div>
            </div>
        );
    };

    /*const variations = item.selectedVariation && typeof item.selectedVariation === 'object'
        ? Object.values(item.selectedVariation).map(variation =>
            formatVariationOrAddon(variation.name, variation.price)
        )
        : null;*/

    const variations = item.selectedVariation && typeof item.selectedVariation === 'object'
        ? formatVariationOrAddon(item.selectedVariation.name, item.selectedVariation.price)
        : null;

    const addons = Array.isArray(item.selectedAddOns)
        ? item.selectedAddOns.map(addon =>
            formatVariationOrAddon(addon.name, addon.price)
        )
        : null;

    const slots = Array.isArray(item.selectedSlots)
        ? item.selectedSlots.map(slot =>
            formatSlots(slot.value, slot.end_value, slot.price)
        )
        : null;

    return (
        <div className="cart_block_items_item">
            <img className="cart_block_items_item_image" src={item.item_image && item.item_image[0]?.path} alt={item.name} />
            <div className="cart_block_items_item_details">
                <div className="cart_block_items_item_name_holder">
                    <div className="cart_block_items_item_name bold">{item.name}</div>
                </div>
                <div className="cart_block_items_item_variants cart_block_items_item_variant_holder">
                    {variations}
                </div>
                <div className="cart_block_items_item_addons cart_block_items_item_addons_holder">
                    {addons}
                </div>
                <div className="cart_block_items_item_addons cart_block_items_item_addons_holder">
                    {slots}
                </div>
            </div>
            {item.price ? (
                <div className="cart_block_items_item_info">
                    <div className="cart_block_items_item_price bold">{item.price}</div>
                    <div className="cart_block_items_item_quantity">
                        <span className="cart_block_items_item_quantity_down button" onClick={() => handleQuantityChange(item.quantity - 1)}>
                            <div className="cart_block_items_item_quantity_down_icon">-</div>
                        </span>
                        <span className="cart_block_items_item_quantity_text">{quantity}</span>
                        <span className="cart_block_items_item_quantity_up button" onClick={() => handleQuantityChange(item.quantity + 1)}>
                            <div className="cart_block_items_item_quantity_up_icon">+</div>
                        </span>
                    </div>
                    <div className="cart_block_items_item_total bold">{(item.price * item.quantity).toFixed(2)}</div>
                </div>
            ) : (
                <div className="cart_block_items_item_info">
                    <div className="cart_block_items_item_price bold">{item.points}</div>
                </div>
            )}
        </div>
    );
};

export default CartBlockItemsItem;
