import React, { useState } from 'react';

const ItemDetailsBlockVariantsGroups = ({ variationGroups = [], onVariationSelect }) => {
    const [selectedVariants, setSelectedVariants] = useState({});

    const handleVariationChange = (groupIndex, group, variant) => {
        setSelectedVariants((prevState) => {
            const groupKey = `group-${groupIndex}`;
            let newSelected = { ...prevState };

            if (group.field_variant_base_price?.[0] === "1") {
                // ✅ Variations: Single selection, override previous selection
                newSelected[groupKey] = variant;
            } else {
                // ✅ Add-ons: Handle single or multiple selections
                if (group.field_variant_multiple?.[0] === "1") {
                    // Multiple selections allowed
                    let selectedAddOns = newSelected[groupKey] ? [...newSelected[groupKey]] : [];

                    if (selectedAddOns.some(v => v.name === variant.name)) {
                        // Remove if already selected
                        selectedAddOns = selectedAddOns.filter(v => v.name !== variant.name);
                    } else {
                        // Add new selection
                        selectedAddOns.push(variant);
                    }

                    newSelected[groupKey] = selectedAddOns;
                } else {
                    // Single selection allowed (replace previous selection)
                    if (newSelected[groupKey]?.name === variant.name) {
                        // Deselect if the same variant is clicked again
                        newSelected[groupKey] = null;
                    } else {
                        // Select the new variant
                        newSelected[groupKey] = variant;
                    }
                }
            }

            onVariationSelect(groupIndex, newSelected[groupKey]); // Pass the updated selection
            return newSelected;
        });
    };

    const isSelected = (groupIndex, group, variant) => {
        const groupKey = `group-${groupIndex}`;
        const selected = selectedVariants[groupKey];

        if (group.field_variant_base_price?.[0] === "1") {
            // Variations: Single selection
            return selected?.name === variant.name;
        } else {
            // Add-ons: Single or multiple selections
            if (group.field_variant_multiple?.[0] === "1") {
                // Multiple selections
                return selected?.some(v => v.name === variant.name);
            } else {
                // Single selection
                return selected?.name === variant.name;
            }
        }
    };

    return (
        <div className="item_details_block_variants_groups">
            {variationGroups.map((group, groupIndex) => (
                <div className="item_details_block_variants" key={groupIndex}>
                    <div className="item_details_block_variants_title bold">{group.field_variant_group_name?.[0]}</div>
                    <div className="item_details_block_variants_groups_list">
                        {group.field_variants?.map((variant, variantIndex) => (
                            <div
                                className={`item_details_block_variants_item ${isSelected(groupIndex, group, variant) ? 'item_details_block_variants_item_selected' : ''}`}
                                key={variantIndex}
                                onClick={() => handleVariationChange(groupIndex, group, variant)}
                            >
                                <input
                                    className="item_details_block_variants_item_indicator"
                                    type={group.field_variant_multiple?.[0] === "1"  ? "checkbox" : "radio"}
                                    name={`group-${groupIndex}`}
                                    value={variant.name}
                                    checked={isSelected(groupIndex, group, variant)}
                                    onChange={() => handleVariationChange(groupIndex, group, variant)}
                                />
                                <div className={`item_details_block_variants_item_name bold ${isSelected(groupIndex, group, variant) ? 'item_details_block_variants_item_name_selected' : ''}`}>
                                    {variant.name}
                                </div>
                                {Number(variant.price) > 0 && (
                                    <div className={`item_details_block_variants_item_price ${isSelected(groupIndex, group, variant) ? 'item_details_block_variants_item_price_selected' : ''}`}>
                                        {variant.price}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ItemDetailsBlockVariantsGroups;
