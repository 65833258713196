import address_block_addresses from '../blocks/address_block_addresses';
import address_block_title from '../blocks/address_block_title';
import address_block_add_new_address_button from '../blocks/address_block_add_new_address_button';
import address_block_form from '../blocks/address_block_form';
import address_block_save_button from '../blocks/address_block_save_button';
import address_block_map from '../blocks/address_block_map';
import block_back from '../blocks/block_back';
import cart_block_items from '../blocks/cart_block_items';
import cart_block_total from '../blocks/cart_block_total';
import cart_block_checkout_button from '../blocks/cart_block_checkout_button';
import cart_block_title from '../blocks/cart_block_title';
import checkout_block_items from '../blocks/checkout_block_items';
import checkout_block_address from '../blocks/checkout_block_address';
import checkout_block_title from '../blocks/checkout_block_title';
import checkout_block_note from '../blocks/checkout_block_note';
import checkout_block_coupon from '../blocks/checkout_block_coupon';
import checkout_block_summary from '../blocks/checkout_block_summary';
import checkout_block_payment from '../blocks/checkout_block_payment';
import checkout_block_confirmation from '../blocks/checkout_block_confirmation';
import checkout_block_confirmation_continue_button from '../blocks/checkout_block_confirmation_continue_button';
import checkout_block_confirm_button from '../blocks/checkout_block_confirm_button';
import item_details_block_images from '../blocks/item_details_block_images';
import item_details_block_price from '../blocks/item_details_block_price';
import item_details_block_quantity from '../blocks/item_details_block_quantity';
import item_details_block_short_description from '../blocks/item_details_block_short_description';
import item_details_block_add_to_cart from '../blocks/item_details_block_add_to_cart';
import item_details_block_variants_groups from '../blocks/item_details_block_variants_groups';
import item_details_block_name from '../blocks/item_details_block_name';
import item_details_block_cart from '../blocks/item_details_block_cart';
import item_details_block_availability from '../blocks/item_details_block_availability';
import item_details_block_specs from '../blocks/item_details_block_specs';
import login_block_logo from '../blocks/login_block_logo';
import login_block_mail from '../blocks/login_block_mail';
import login_block_title from '../blocks/login_block_title';
import login_block_signup from '../blocks/login_block_signup';
import login_block_forgot_password from '../blocks/login_block_forgot_password';
import login_block_facebook from '../blocks/login_block_facebook';
import login_block_google from '../blocks/login_block_google';
import login_block_skip from '../blocks/login_block_skip';
import login_block_social from '../blocks/login_block_social';
import login_block_apple from '../blocks/login_block_apple';
import login_block_terms from '../blocks/login_block_terms';
import login_block_register from '../blocks/login_block_register';
import orders_block_orders from '../blocks/orders_block_orders';
import orders_block_title from '../blocks/orders_block_title';
import orders_block_header from '../blocks/orders_block_header';
import orders_block_address from '../blocks/orders_block_address';
import orders_block_items from '../blocks/orders_block_items';
import orders_block_summary from '../blocks/orders_block_summary';
import product_catalog_block_logo from '../blocks/product_catalog_block_logo';
import product_catalog_block_categories from '../blocks/product_catalog_block_categories';
import product_catalog_block_items from '../blocks/product_catalog_block_items';
import product_catalog_block_cart from '../blocks/product_catalog_block_cart';
import product_catalog_block_offers from '../blocks/product_catalog_block_offers';
import product_catalog_block_menu_icon from '../blocks/product_catalog_block_menu_icon';
import product_catalog_block_user_menu from '../blocks/product_catalog_block_user_menu';
import product_catalog_block_contact from '../blocks/product_catalog_block_contact';
import product_catalog_block_whatsapp from '../blocks/product_catalog_block_whatsapp';
import product_catalog_block_user_account from '../blocks/product_catalog_block_user_account';
import product_catalog_block_overlay_title from '../blocks/product_catalog_block_overlay_title';
import product_catalog_block_subcategories from '../blocks/product_catalog_block_subcategories';
import product_catalog_block_listing_group from '../blocks/product_catalog_block_listing_group';
import product_catalog_block_loyalty_program from '../blocks/product_catalog_block_loyalty_program';
import product_catalog_block_loyalty_items from '../blocks/product_catalog_block_loyalty_items';
import product_catalog_block_wallet from '../blocks/product_catalog_block_wallet';
import product_catalog_block_tabs from '../blocks/product_catalog_block_tabs';
import product_catalog_block_footer from '../blocks/product_catalog_block_footer';
import splash_block_background from '../blocks/splash_block_background';
import splash_block_logo from '../blocks/splash_block_logo';
import splash_block_slogan from '../blocks/splash_block_slogan';
import splash_block_copyrights from '../blocks/splash_block_copyrights';

const componentMapper = {
    address_block_addresses,
    address_block_title,
    address_block_add_new_address_button,
    address_block_form,
    address_block_save_button,
    address_block_map,
    block_back,
    cart_block_items,
    cart_block_total,
    cart_block_checkout_button,
    cart_block_title,
    checkout_block_items,
    checkout_block_address,
    checkout_block_title,
    checkout_block_coupon,
    checkout_block_note,
    checkout_block_summary,
    checkout_block_payment,
    checkout_block_confirm_button,
    checkout_block_confirmation,
    checkout_block_confirmation_continue_button,
    item_details_block_name,
    item_details_block_images,
    item_details_block_price,
    item_details_block_short_description,
    item_details_block_quantity,
    item_details_block_variants_groups,
    item_details_block_add_to_cart,
    item_details_block_cart,
    item_details_block_availability,
    item_details_block_specs,
    login_block_logo,
    login_block_title,
    login_block_mail,
    login_block_signup,
    login_block_facebook,
    login_block_social,
    login_block_apple,
    login_block_forgot_password,
    login_block_google,
    login_block_skip,
    login_block_terms,
    login_block_register,
    orders_block_orders,
    orders_block_title,
    orders_block_header,
    orders_block_address,
    orders_block_items,
    orders_block_summary,
    product_catalog_block_logo,
    product_catalog_block_categories,
    product_catalog_block_items,
    product_catalog_block_cart,
    product_catalog_block_offers,
    product_catalog_block_menu_icon,
    product_catalog_block_user_menu,
    product_catalog_block_contact,
    product_catalog_block_whatsapp,
    product_catalog_block_user_account,
    product_catalog_block_overlay_title,
    product_catalog_block_subcategories,
    product_catalog_block_listing_group,
    product_catalog_block_loyalty_program,
    product_catalog_block_loyalty_items,
    product_catalog_block_wallet,
    product_catalog_block_tabs,
    product_catalog_block_footer,
    splash_block_background,
    splash_block_logo,
    splash_block_slogan,
    splash_block_copyrights,
};

export default componentMapper;