import React, { useEffect, useState } from 'react';
import { fetchLoyaltyPoints } from '../services/api';
import { isLoggedIn } from '../services/auth';
import { useTranslation } from "react-i18next";
import { useAppId } from "../AppIdContext";
import { useNavigate } from 'react-router-dom';
import cart from '../services/cart'; // Import the cart service

const ProductCatalogBlockLoyaltyProgram = () => {
    const [loyaltyPoints, setLoyaltyPoints] = useState(0);
    const [actualUserCredits, setActualUserCredits] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { t } = useTranslation();
    const appId = useAppId();
    const navigate = useNavigate();

    useEffect(() => {
        if (isLoggedIn()) {
            fetchLoyaltyPoints(appId)
                .then(credit => {
                    if (credit.length > 0) {
                        const userCredits = credit[0].balance_after;
                        setLoyaltyPoints(userCredits);
                        const actualCredits = cart.getActualUserCredits(userCredits);
                        setActualUserCredits(actualCredits);
                    }
                    setLoading(false);
                })
                .catch(err => {
                    setError(err.message);
                    setLoading(false);
                });
        }
    }, [isLoggedIn(), appId]);

    if (!isLoggedIn() || loading || error) {
        return null;
    }

    const handleNavigate = () => {
        navigate('/overlay', {
            state: {
                selectedCategoryId: null,
                selectedCategoryName: t("product_catalog_block_loyalty_items_title_text"),
                config: {}, // Assuming you have config to pass
                userCredits: actualUserCredits,
                mode: 'loyalty',
            }
        });
    };

    return (
        <div className="product_catalog_block_loyalty_program block_group draggable">
            <div className="product_catalog_block_loyalty_program_holder flex">
                <div className="product_catalog_block_loyalty_program_credit flex_1 flex">
                    <div className="product_catalog_block_loyalty_program_credit_title product_catalog_block_loyalty_program_credit_title_text bold">{t("product_catalog_block_loyalty_program_credit_title_text")}</div>
                    <div className="product_catalog_block_loyalty_program_credit_points bold">{actualUserCredits}</div>
                </div>
                <div
                    className="product_catalog_block_loyalty_program_button product_catalog_block_loyalty_program_button_text pointer bold"
                    onClick={handleNavigate}
                >
                    {t("product_catalog_block_loyalty_program_button_text")}
                </div>
            </div>
            <div className="product_catalog_block_loyalty_program_description product_catalog_block_loyalty_program_description_text">{t("product_catalog_block_loyalty_program_description_text")}</div>
        </div>
    );
};

export default ProductCatalogBlockLoyaltyProgram;
