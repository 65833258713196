import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

const ProductCatalogBlockFooter = ({ appId }) => {
    const { t } = useTranslation();
    const [modalContent, setModalContent] = useState(null);
    const [modalTitle, setModalTitle] = useState("");
    const modalBodyRef = useRef(null);

    const openModal = (titleKey, contentKey) => {
        setModalTitle(t(titleKey));
        setModalContent(t(contentKey).split('\n').map((line, index) => (
            <span key={index}>{line}<br/></span>
        )));
    };

    const closeModal = () => {
        setModalContent(null);
        setModalTitle("");
    };

    useEffect(() => {
        if (modalBodyRef.current) {
            modalBodyRef.current.scrollTop = 0;
        }
    }, [modalContent]);

    return (
        <div className="product_catalog_block_footer">
            <div className="product_catalog_block_footer_links">
                <a className="product_catalog_block_footer_links_link pointer" onClick={() => openModal("product_catalog_block_footer_privacy_title_text", "product_catalog_block_footer_privacy_body_text")}>{t("product_catalog_block_footer_privacy_title_text")}</a>
                <a className="product_catalog_block_footer_links_link pointer" onClick={() => openModal("product_catalog_block_footer_shipping_title_text", "product_catalog_block_footer_shipping_body_text")}>{t("product_catalog_block_footer_shipping_title_text")}</a>
                <a className="product_catalog_block_footer_links_link pointer" onClick={() => openModal("product_catalog_block_footer_terms_title_text", "product_catalog_block_footer_terms_body_text")}>{t("product_catalog_block_footer_terms_title_text")}</a>
            </div>
            <p className="product_catalog_block_footer_copyrights">{t("product_catalog_block_footer_copyrights_text")}</p>

            {modalContent && (
                <div className="modal-overlay" onClick={closeModal}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <button className="modal-close" onClick={closeModal}>&times;</button>
                        <h2 className="modal-title">{modalTitle}</h2>
                        <div className="modal-body" ref={modalBodyRef}>
                            <p>{modalContent}</p>
                        </div>
                    </div>
                </div>
            )}


        </div>
    );
};

export default ProductCatalogBlockFooter;
