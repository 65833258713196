import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import ComponentRenderer from "../utils/ComponentRenderer";
import { useAppId } from "../AppIdContext";
import Cart from '../services/cart';
import {fetchDefaultAddresses, fetchBranches, saveContent, getKashierLink, fetchOrderDetails} from '../services/api';

const Checkout = ({ config }) => {
    const [checkoutPlacements, setPlacements] = useState(null);
    const appId = useAppId();

    useEffect(() => {
        const loadPlacements = async () => {
            try {
                const placements = await import(`../projects/${appId}/placements/checkout_placements.json`);
                setPlacements(placements.default);
            } catch (error) {
                console.error('Failed to load placements:', error);
            }
        };
        loadPlacements();
    }, [appId]);

    const [address, setAddress] = useState(null);
    const [freeService, setFreeService] = useState(false);
    const [tax_ratio, setTax] = useState(0);
    const [status, setStatus] = useState(null);
    const [coupon, setCoupon] = useState(null);
    const [note, setNote] = useState('');
    const [serviceFee, setServiceFee] = useState(0);
    const [paymentMethod, setPaymentMethod] = useState("COD");
    const [useCredit, setUseCredit] = useState(false);
    const [isReservation, setIsReservation] = useState(true);
    const [walletbalance, setWalletBalance] = useState(0);
    const [walletAmount, setWalletAmount] = useState(0);
    const [pending, setPending] = useState(0);
    const [orderSummary, setOrderSummary] = useState({
        subtotal: Cart.getSubtotal(),
        discount: 0,
        tax: 0,
        serviceFee: 0,
        walletAmount: 0,
        total: 0,
    });
    const navigate = useNavigate();

    useEffect(() => {
        const fetchAddressAndAutoApplyCoupon = async () => {
            try {
                const addresses = await fetchDefaultAddresses(appId);
                if (addresses.length === 0) {
                    navigate('/address/form', { state: { destination: '/checkout' } });
                } else {
                    setAddress(addresses[0]);
                }
            } catch (error) {
                console.error('Failed to fetch default address:', error);
            }
        };
        fetchAddressAndAutoApplyCoupon();
    }, [appId, navigate]);

    useEffect(() => {
        const loadBranchDetails = async () => {
            try {
                const branchDetails = await fetchBranches(appId);
                if (branchDetails && branchDetails.length > 0) {
                    const coverage = branchDetails[0].coverage;
                    setStatus(branchDetails[0].workflow[0].value);
                    setTax(branchDetails[0].tax);
                    calculateServiceFee(coverage, address, branchDetails[0].tax ?? 0);
                }
            } catch (error) {
                console.error('Failed to load branch details:', error);
            }
        };
        if (address) {
            loadBranchDetails();
        }
    }, [address, appId, freeService, useCredit]);



    useEffect(() => {
        const items = Cart.getItems();
        const hasEmptySlots = items.some(item => Array.isArray(item.selectedSlots) && item.selectedSlots.length === 0);
        setIsReservation(!hasEmptySlots);
    }, [Cart.getItems]);

    const calculateServiceFee = (coverage, address, tax) => {
        let fee = 0;
        if (!isReservation) {
            for (let province of coverage) {
                if (province.name === address.province) {
                    fee = parseFloat(province.price) || fee;
                    for (let city of province.cities) {
                        if (city.name === address.city) {
                            fee = parseFloat(city.price) || fee;
                            for (let area of city.areas) {
                                if (area.name === address.area) {
                                    fee = parseFloat(area.price) || fee;
                                    break;
                                }
                            }
                            break;
                        }
                    }
                    break;
                }
            }


        } /*else {
            //setTax(0);
            //tax = 0;
        }*/


        // Final service fee calculation
        const finalFee = freeService ? 0 : fee;

        let total = orderSummary.subtotal - orderSummary.discount + ((orderSummary.subtotal - orderSummary.discount) / 100) * tax + finalFee;

        // Declare walletUsed locally
        let walletUsed = 0;

        // Deduct from wallet if useCredit is true
        if (useCredit && walletbalance > 0) {
            walletUsed = Math.min(walletbalance, total); // Use only the required wallet balance
            total -= walletUsed; // Deduct from total
            setWalletAmount(walletUsed); // Set the used wallet amount
        } else {
            setWalletAmount(0); // Reset walletAmount if useCredit is false
        }

        // Update order summary with the calculated values
        setServiceFee(finalFee);
        setOrderSummary(prevSummary => ({
            ...prevSummary,
            tax: ((orderSummary.subtotal - orderSummary.discount) / 100) * tax,
            serviceFee: finalFee,
            walletAmount: walletUsed, // Use walletUsed directly here
            total: total, // Update the total with wallet deduction
        }));
    };




    const handleCouponApply = (couponData) => {
        const { condition_value, type, value } = couponData;
        const subtotal = orderSummary.subtotal;

        if (subtotal >= condition_value) {
            let discount = 0;
            if (type === 'fixed') {
                discount = parseFloat(value);
            } else if (type === 'percentage') {
                discount = (subtotal * parseFloat(value)) / 100;
            } else if (type === 'free_service') {
                setFreeService(true);
                setServiceFee(0);
            } else {
                setFreeService(false);
            }

            let total = subtotal - discount + orderSummary.tax + (freeService ? 0 : serviceFee);

            let  walletUsed = 0;
            // Deduct from wallet if useCredit is true
            if (useCredit && walletbalance > 0) {
                walletUsed = Math.min(walletbalance, total); // Use only the required wallet balance
                setWalletAmount(walletUsed); // Set the used wallet amount
                total -= walletUsed; // Deduct from total
            } else {
                setWalletAmount(0); // Reset walletAmount if useCredit is false
            }


            setCoupon(couponData);
            setOrderSummary(prevSummary => ({
                ...prevSummary,
                discount: discount,
                walletAmount: walletUsed,
                total: total, // Update the total with wallet deduction
            }));
            return true;
        }

        return false;
    };




    const handleAddressChange = (newAddress) => {
        setAddress(newAddress);
    };

    const handleNoteChange = (newNote) => {
        setNote(newNote);
    };

    const handlePaymentMethod = (method) => {
        setPaymentMethod(method);
        if (method != "COD") {
            setPending(1);
        }
    };

    const handleUseCredit = (useCredit, walletBalance) => {
        console.log(useCredit);
        setUseCredit(useCredit);
        setWalletBalance(walletBalance);
    };

    const handleConfirmOrder = () => {
        if (!address) {
            toast.error("Address is required.");
            return;
        }

        const orderItems = Cart.getItems().map(item => {
            // Format variation or addon name and price
            const formatVariationOrAddon = (name, price) => `${name} X ${price}`;

            // Handle variations
            const variations = item.selectedVariation && typeof item.selectedVariation === 'object'
                ? Object.values(item.selectedVariation).map(variation =>
                    formatVariationOrAddon(variation.name, variation.price)
                )
                : [];

            const points = item.points ?  [item.points] : [];


            return {
                field_ordered_quantity: [item.quantity],
                field_ordered_addons: Array.isArray(item.selectedAddOns) ? item.selectedAddOns.map(addon => formatVariationOrAddon(addon.name, addon.price)) : [],
                field_ordered_points: points,
                field_ordered_price: [item.price],
                field_ordered_item_name: [item.name],
                field_ordered_item: {
                    target_id: item.id
                },
                field_ordered_variant: variations,
                field_ordered_service_time: item.selectedSlots,
                field_ordered_sku: [item.sku || ""]
            };
        });

        const formattedAddress = `Name: ${address.full_name}, Phone: ${address.phone}, Flat: ${address.flat}, Building: ${address.building}, ${address.street}, ${address.city}, ${address.province}`;

        const orderData = {
            subtotal: orderSummary.subtotal,
            tax: orderSummary.tax,
            coupon: coupon ? coupon.code : "",
            discount: orderSummary.discount,
            service_fee: orderSummary.serviceFee,
            total: orderSummary.total,
            field_order_item: orderItems,
            pending: pending,
            status: status,
            payment: paymentMethod,
            service_type: "delivery",
            platform: "web",
            wallet_amount: walletAmount,
            note: note,
            address: formattedAddress,
            phone: address.phone,
            province: address.province,
            city: address.city,
            area: address.area,
            lng: address.lng,
            lat: address.lat
        };



        saveContent('apps_order', orderData).then(async (response) => {
            if (response.id) {
                const orderNumber = response.id;
                const order_details = await fetchOrderDetails(response.id);
                if (orderData.payment === "kashier" && orderData.pending === 1) {
                    try {
                        // 1 - Generate payment link from the API if payment method is Kashier and the order is pending
                        const paymentLinkResponse = await getKashierLink(order_details[0]);
                        const paymentLink = paymentLinkResponse.link;

                        // 2 - Redirect user to a new view called kashierPayment which contains the payment link in an iframe
                        navigate('/kashierPayment', { state: { paymentLink, orderNumber}});

                    } catch (error) {
                        console.error('Failed to process payment:', error);
                        toast("Failed to process payment!");
                    }
                } else {
                    // If no Kashier payment, process the order normally
                    toast("Order placed successfully!");
                    Cart.clearCart();
                    navigate('/checkout/confirmation', { state: { orderNumber: response.id } });
                }
            } else {
                toast("Failed to place order!");
            }
        }).catch(error => {
            console.error('Failed to place order:', error);
            toast("Failed to place order!");
        });

    };

    const data = {
        checkout_block_address: {
            address: address,
            onAddressChange: handleAddressChange,
        },
        checkout_block_note: {
            note: note,
            onNoteChange: handleNoteChange,
        },
        checkout_block_summary: {
            summary: orderSummary,
        },
        checkout_block_coupon: {
            onCouponApplied: handleCouponApply,
        },
        checkout_block_confirm_button: {
            onConfirmOrder: handleConfirmOrder,
        },

        checkout_block_payment: {
            setPaymentMethod: handlePaymentMethod,
            setUseCredit: handleUseCredit,
        },
    };

    if (!checkoutPlacements) {
        return <div></div>; // Show a loading state while configuration or data is being loaded
    }

    const renderer = new ComponentRenderer(checkoutPlacements, data);

    return (
        <div className="checkout_layout">
            <ToastContainer />
            <div className="checkout_layout_toolbar toolbar">
                <div className="checkout_layout_header_position_header_start toolbar_start">{renderer.renderComponents('checkout_layout_header_position_header_start')}</div>
                <div className="checkout_layout_header_position_header_center toolbar_center">{renderer.renderComponents('checkout_layout_header_position_header_center')}</div>
                <div className="checkout_layout_header_position_header_end toolbar_end">{renderer.renderComponents('checkout_layout_header_position_header_end')}</div>
            </div>
            <div className="checkout_layout_content_position_content checkout_layout_content">{renderer.renderComponents('checkout_layout_content_position_content')}</div>
            {config.checkout_layout_footer_enable && (
                <div className="checkout_layout_footer_holder">
                    <div className="checkout_layout_footer">
                        <div className="checkout_layout_footer_positions">
                            <div className="checkout_layout_footer_position_footer_start">{renderer.renderComponents('checkout_layout_footer_position_footer_start')}</div>
                            <div className="checkout_layout_footer_position_footer_center">{renderer.renderComponents('checkout_layout_footer_position_footer_center')}</div>
                            <div className="checkout_layout_footer_position_footer_end">{renderer.renderComponents('checkout_layout_footer_position_footer_end')}</div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Checkout;
