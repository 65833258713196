import React, { useState, useEffect, useCallback } from 'react';
import ComponentRenderer from "../utils/ComponentRenderer";
import { useAppId } from "../AppIdContext";
import { useLocation, useNavigate } from 'react-router-dom';
import { saveContent } from '../services/api';
import { ToastContainer, toast } from 'react-toastify';
import {useTranslation} from "react-i18next";


const AddressForm = ({ config   }) => {
    const location = useLocation();
    const address = location.state?.address || null;
    const destination = location.state?.destination || '/address';
    const [addressFormPlacements, setPlacements] = useState(null);
    const appId = useAppId();
    const navigate = useNavigate();
    let defaultCenter;

    const { t } = useTranslation();

    const notify_error = (message) => toast.error(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });


    const notify_success = (message) => toast.success(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });


    const [formValues, setFormValues] = useState({
        id: address?.id || null,
        default: address?.default || false,
        full_name: address?.full_name || '',
        phone: address?.phone || '',
        mail: address?.mail || '',
        province: address?.province || '',
        city: address?.city || '',
        area: address?.area || '',
        flat: address?.flat || '',
        building: address?.building || '',
        street: address?.street || '',
        postal_code: address?.postal_code || '',
        lat: parseFloat(address?.lat) || null,
        lng: parseFloat(address?.lng) || null
    });

    useEffect(() => {
        const loadPlacements = async () => {
            try {
                const placements = await import(`../projects/${appId}/placements/address_form_placements.json`);
                setPlacements(placements.default);
            } catch (error) {
                console.error('Failed to load placements:', error);
            }
        };

        loadPlacements();
    }, [appId]);


    const validateFields = () => {
        const errors = [];
        if (config.address_block_form_full_name_required && !formValues.full_name) {
            errors.push(t("address_block_form_input_full_name_error"));
        }
        if (config.address_block_form_phone_required && !formValues.phone) {
            errors.push(t("address_block_form_input_phone_error"));
        }
        if (config.address_block_form_mail_required && !formValues.mail) {
            errors.push(t("address_block_form_input_mail_error"));
        }
        if (config.address_block_form_flat_required && !formValues.phone) {
            errors.push(t("address_block_form_input_flat_error"));
        }
        if (config.address_block_form_building_required && !formValues.building) {
            errors.push(t("address_block_form_input_building_error"));
        }
        if (config.address_block_form_street_required && !formValues.street) {
            errors.push(t("address_block_form_input_street_error"));
        }
        if (config.address_block_form_postal_code_required && !formValues.postal_code) {
            errors.push(t("address_block_form_input_postal_code_error"));
        }
        if (config.address_block_form_area_required && !formValues.area) {
            errors.push(t("address_block_form_input_area_error"));
        }
        if (config.address_block_form_city_required && !formValues.city) {
            errors.push(t("address_block_form_input_city_error"));
        }
        if (config.address_block_form_province_required && !formValues.province) {
            errors.push(t("address_block_form_input_province_error"));
        }

        return errors;
    };

    const handleSaveAddress = async () => {
        const errors = validateFields();
        if (errors.length > 0) {
            errors.forEach(error => {
                notify_error(`Error: ${error}`);
                console.log(error);
            });
            return;
        }

        const addressToSave = { ...formValues };
        if (addressToSave.id === null) {
            delete addressToSave.id;
        }

        console.log(addressToSave);

        try {
            const response = await saveContent('apps_address', addressToSave);
            console.log('Address saved successfully:', response);
            const { id } = response;
            if (id) {
                console.log(`Address saved with ID: ${id}`);
                navigate(destination);
                notify_success(`Address saved!`);
            }

        } catch (error) {
            console.error('Failed to save address:', error);
            notify_error(`Error: ${error}`);
        }
    };

    const handleFormChange = useCallback((newValues) => {
        setFormValues((prevValues) => {
            const updatedValues = { ...prevValues, ...newValues };
            if (JSON.stringify(updatedValues) !== JSON.stringify(prevValues)) {
                return updatedValues;
            }
            return prevValues;
        });
    }, []);

    const handleLocationChange = (location) => {
        setFormValues(prev => ({
            ...prev,
            lat: location.lat,
            lng: location.lng
        }));

        console.log(location);
    };


    if (address?.lat && address?.lng) {
        defaultCenter = {
            lat: formValues?.lat ,
            lng: formValues?.lng
        };
    } else {
        defaultCenter = null;
    }


    const data = {
        block_back: {
            appId: appId,
        },
        address_block_map: {
            defaultCenter: defaultCenter,
            onLocationChange: handleLocationChange,
        },
        address_block_form: {
            config,
            address,
            onFormChange: handleFormChange,
        },
        address_block_save_button: {
            handleSaveAddress: handleSaveAddress,
        },
    };

    if (!addressFormPlacements) {
        return <div></div>; // Show a loading state while configuration or data is being loaded
    }

    const renderer = new ComponentRenderer(addressFormPlacements, data);

    return (
        <div className="address_layout">
            <div className="address_layout_toolbar toolbar">
                <div className="address_layout_header_position_header_start toolbar_start">{renderer.renderComponents('address_layout_header_position_header_start')}</div>
                <div className="address_layout_header_position_header_center toolbar_center">{renderer.renderComponents('address_layout_header_position_header_center')}</div>
                <div className="address_layout_header_position_header_end toolbar_end">{renderer.renderComponents('address_layout_header_position_header_end')}</div>
            </div>
            <div className="address_layout_content_position_map">{renderer.renderComponents('address_layout_content_position_map')}</div>
            <div className="address_layout_content_position_content address_layout_content">{renderer.renderComponents('address_layout_content_position_content')}</div>
            <div className="address_layout_footer_holder">
                <div className="address_layout_footer">
                    <div className="address_layout_footer_positions">
                        <div className="address_layout_footer_position_footer_start">{renderer.renderComponents('address_layout_footer_position_footer_start')}</div>
                        <div className="address_layout_footer_position_footer_center flex_1">{renderer.renderComponents('address_layout_footer_position_footer_center')}</div>
                        <div className="address_layout_footer_position_footer_end">{renderer.renderComponents('address_layout_footer_position_footer_end')}</div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default AddressForm;
